import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/KylieBellContent'
import OurHistory from '../components/AboutUs/OurHistory'
import HowItWork from '../components/AboutUs/HowItWork'
import Partner from '../components/AboutUs/Partner'
 
const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="About Kylie Bell" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About Kylie Bell" 
            />
            <AboutUsContent />
            <Partner />
            <Footer />
        </Layout>
    );
}

export default AboutUs;