import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/KOT_Site.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Kylie Bell
                                </span>
                                <h2>About Kylie Bell</h2>
                                <p>Kylie Bell the founder of <b>Success Occupational Therapy</b> is passionate about helping members of the Western Australian community improve and sustain their quality of life through participation in meaningful, necessary and familiar activites.</p>
                                <p>Kylie's experience ranges over 30 years and over two continents. Graduating from Curtin University she quickly established herself as leader in the Department of Health working with adults to reduce their length of stay and prevent readmission into hospital.</p>
                                <p>Opportunities arose that would allow her to progress her career in the United States, working in sub-acute rehabilitation for adults and older adults she focused on getting her clients out of managed care and back into their home.</p>
                                <p>While living in the US was good, there was always that nagging need to return home. After a six year stretch, Kylie returned home to take up roles in the Aged Care sector.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Values</h3>
                                <p>Positive experiences through successful outcomes.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Respect</li>
                                    <li><i className="flaticon-tick"></i> Trust</li>
                                    <li><i className="flaticon-tick"></i> Client Focused</li>
                                    <li><i className="flaticon-tick"></i> Passion</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>To deliver meaningful outcomes for our clients that provide a better quality of life for them.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Ensuring we can live Independantly longer</li>
                                    <li><i className="flaticon-tick"></i> Promote a life with purpose</li>
                                    <li><i className="flaticon-tick"></i> Advocate for help where its needed</li>
                                    <li><i className="flaticon-tick"></i> Staying connected to the community</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>We work as a team to deliver outcomes for our clients that have meaning.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Caring</li>
                                    <li><i className="flaticon-tick"></i> Professional</li>
                                    <li><i className="flaticon-tick"></i> Practical</li>
                                    <li><i className="flaticon-tick"></i> Authentic</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;